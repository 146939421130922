import {Component, Input, OnInit} from '@angular/core';
import {DocumentsService} from '../../../services/documents.service';
import {FilesService} from '../../../services/files.service';
import {Document as DocumentLesson} from '../../../models/document';
import {File as COAFile} from '../../../models/file';
import { LessonsService } from 'src/app/services/lessons.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit {
  @Input('index') index;
  document: DocumentLesson;

  constructor(
    private documentsService: DocumentsService,
    filesService: FilesService,
    private lessonsService: LessonsService,
    private toastr: ToastrService
    ) {
    filesService.register();
  }

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments(){
    this.documentsService.get(this.index, {include: ['files']}).subscribe(
      document => (this.document = document),
      () => {
      },
      () => {
        //console.log('document lesson ::', this.document.relationships.files.data);
      }
    );

    
  }

  get files(): COAFile[] {
    return this.document?.relationships.files.data ?? [];
  }


  addFavorite(file: COAFile){
    this.lessonsService.addFavorite({file_id : file.id})
    .subscribe((res: any) => {
        if (res) {
          this.getDocuments();
          this.toastr.success('Doc favorite successfully added');
        } 
      },
      err => {
        this.toastr.error('Error while adding doc favorite');
        //console.log(err);
      });
  }

  deleteFavorite(file: COAFile){
    this.lessonsService.deleteFavorite({file_id : file.id})
    .subscribe((res: any) => {
        if (res) {
          this.getDocuments();
          this.toastr.success('Doc favorite successfully deleted');
        } 
      },
      err => {
        this.toastr.error('Error while deleting doc favorite');
        //console.log(err);
      });
  }
}
