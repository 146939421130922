<div class="title-lesson-document my-4">{{'documents'|translate}}</div>
<div *ngIf="document">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-4 col-md-6 col-sm-6 mt-5" *ngFor="let f of files">
        <div class="card-lesson position-relative" >
          <div class="header-card-lesson">
            <div class="header-card-lesson__vertical">
                <div class="lesson-name">
                  <img src='/assets/img/document-2.svg' class="image" alt="image">
                  <span class="lesson-name__content">{{f.attributes.name}}</span>
                </div>
            </div>
            </div>
            <div class="footer-card-lesson d-flex flex-row justify-content-between align-items-center">
                <p  class="document-footer col">{{'Lesson'|translate}}</p>
                <a href="{{f.attributes.url}}" download target="_blank"> <img src="/assets/img/download-lesson.svg" alt="download"></a>
                <button class="col" *ngIf="!f.attributes.isFavorite" (click)="addFavorite(f)" class="float-right"><img  src="/assets/img/favorite-lesson.svg" alt="favorite"></button>
                <button class="col" *ngIf="f.attributes.isFavorite" (click)="deleteFavorite(f)" class="float-right"><img  src="/assets/img/star.svg" alt="favorite"></button>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

