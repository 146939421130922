// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const baseUrl='http://localhost:8000'
const baseUrl = 'https://oa-apielearning.isogm.fr';
const frontUrl = 'https://oa-elearning.isogm.fr';
const backUrl = 'http://oa-adminelearning.isogm.fr';

export const environment = {

  host: frontUrl,
  production: false,
  dp_baseUrl: backUrl,

  base_url: baseUrl,
  api_url: baseUrl + '/api/v1/',
  auth_url: baseUrl + '/api/login-2fa',
  browser_verify_url: baseUrl + '/api/validate-2fa',
  validate_phone_url: baseUrl + '/api/validate-phone',
  confirm_phone_url: baseUrl + '/api/confirm-phone',
  request_email_update_url: baseUrl + '/api/request-update-email',
  confirm_email_update_url: baseUrl + '/api/update-email',
  confirm_email_url: baseUrl + '/api/confirm-email',
  register: baseUrl + '/api/register',
  avatars: baseUrl + '/storage',
  uploadAvatar: baseUrl + '/api/upload/avatar',
  update_profile: baseUrl + '/api/update-profile',
  change_old_password: baseUrl + '/api/change-old-password',
  delete_account: baseUrl + '/api/delete-account',
  store_user_note: baseUrl+'/api/store-user-note',
  store_notification_status: baseUrl+'/api/store_notification_status',

  BROWSER_ID_HEADER: 'X-COA-BROWSER-ID',

  authenticated: {
    chat_auth: baseUrl + '/api/chat/token'
  },

  zoom: {
    key: '5x3eZruhS6aK9va9luvEkg',
    signatureUrl: baseUrl + '/api/zoom/signature',
    video: baseUrl + '/api/zoom/videos'
  },

  public_storage: baseUrl + '/storage/',
  upload_answer_file: baseUrl + '/api/test-submission/upload',
  submit_answers: baseUrl + '/api/test-submission/{test}/submit',
  submit_exams_answers: baseUrl + '/api/exam-submission/{exam}/submit',
  participation: baseUrl + '/api/participation/{course}',
  participate_in_course: baseUrl + '/api/participation/{course}/participate',

  request_password_reset: baseUrl + '/api/request-password-reset',
  change_password: baseUrl + '/api/change-password',

  get_test_submission: baseUrl + '/api/test-submission/{test}',
  get_exam_submission: baseUrl + '/api/exam-submission/{exam}',
  upload_exam_answer_file: baseUrl + '/api/exam-submission/upload',
  cancel_participate_in_course: baseUrl + '/api/participation/{course}/cancel',

  submit_group_work_answers: baseUrl + '/api/group-work-submission/{group-work}/submit',
  get_group_work_submission: baseUrl + '/api/group-work-submission/{group-work}',
  upload_group_work_answer_file: baseUrl + '/api/test-submission/upload',

  participation_list: baseUrl + '/api/participation/{course}/list',
  participation_module_status: baseUrl + '/api/participation/status/{module}',
  participation_list_user: baseUrl + '/api/participation/list',

  repeat_exam: baseUrl + '/api/exam-submission/{exam}/repeat',
  update_group_work: baseUrl + '/api/group-work-submission/{group-work}/update',

  get_user_evaluations: baseUrl + '/api/modules/{module}/evaluations',
  get_course_result: baseUrl + '/api/courses/{course}/evaluations',
  get_certificate_path: baseUrl + '/api/courses/{course}/certificate/{user_id}',
  certificates: baseUrl + '/storage/certificates',

  add_file_favorite: baseUrl + '/api/add-favorite/files',
  delete_file_favorite: baseUrl + '/api/delete-favorite/files',
  get_files_favorites: baseUrl + '/api/files/favorites',

  get_invite_organisation: baseUrl + '/api/invite/organisation/{code_invite}',
  get_organisation: baseUrl + '/api/organisation/{organisation}',
  create_chat: baseUrl + '/api/chat/create/{user}',
  create_channel: baseUrl + '/api/chat/createChannel',
  createOrUpdate_Hotline_User: baseUrl + '/api/hotline/createOrUpdateHotlineUser',
  create_Ticket :baseUrl + '/api/hotline/createTicket',
  getChannel:baseUrl+'/api/chat/verifChannel/{friendlyname}',
  chat_auth_anonym: baseUrl + '/api/hotline/generateChatTokenForHotlineUser',

  get_users: baseUrl + '/api/chat/users/private'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
